<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" back="Convention.Checkout.Notifications">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body class="interest" :loading="is.loading && is.initialised" v-if="is.initialised">

		<app-content-box>

			<h1>How waiting lists work</h1>

			<p>When you wish to check out a game from the library but someone else has already got it, you can optionally add yourself to the game's waiting list. You can either do that here or ask a member of the team at the library.</p>
			
			<p>As soon as a copy of the game is returned to the library the first person on that game's waiting list will receiving a notification. They will have 10 minutes to check out the copy before the next person on the waiting list is notified, and so on.</p>
			
			<p>The waiting lists do not guarentee availablity as another attendee may coincidentally check out the game during that 10 minute window - it is a first come, first served policy. You will be notified if a game becomes unavailable again before you check it out.</p>

			<p>If you are no longer interested in obtaining a copy of the game then please remember to remove yourself from the waiting list, this will help other attendees get their copy quicker.</p>

		</app-content-box>

		<app-content-box>

			<app-input-library label="Game" type="available" :text="libraryMessages" v-model="model.game" :validation="$v.model.game" v-on:statusChange="onStatusChange" />

		</app-content-box>

		<app-content-box :disabled="!isUnavailable">

			<app-input-toggle label="Notify by E-mail" v-model="model.via.email" :validation="$v.model.via.email" />
			<app-input-toggle label="Notify by SMS" v-model="model.via.sms" :validation="$v.model.via.sms" notes="Make sure you have entered your number in your profile." />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			forceModelLoad: true,
			isUnavailable: false,
			model: {
				id: false,
				game: false,
				via: {
					email: true,
					sms: true
				}
			},
			libraryMessages: {
				info: 'A game must be currently checked out for you to add it to your waiting list.',
				unavailable: 'All copies of this game are currently checked out from the library. You can add yourself to the waiting list for this game.',
				available: 'At least one copy of this game is currently available in the library. There is no need to add yourself to a waiting list for this game.',
				none: 'There are no copies of this game in the library and so it is not possible to add yourself to the waiting list.'
			}
		}

	},

	validations: {
		model: {
			game: {
				required
			},
			via: {

			}
		}
	},

	computed: {

		isValid: function() {

			return this.isUnavailable && !this.$v.$invalid

		},

		title: function() {

			return (this.isNew) ? 'Add game' : this.item.name
		
		}

	},

	methods: {

		onCreateNew: function(params) {

			if (this.$route.query.game) params.game = this.$route.query.game

			return params

		},

		onStatusChange: function(e) {

			this.isUnavailable = e.unavailable

		},

		onSaved: function() {

			this.$router.push({
				name: 'Convention.Checkout.Notifications'
			})

		}

	}

}

</script>

<style scoped>

</style>